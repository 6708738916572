import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, Divider, ListItemIcon } from "@mui/material";
import { PowerSettingsNew } from "@mui/icons-material";
import { useAuth } from "context/UseContext";
import Colors from "assets/Style/Colors";
import { FontFamily, GalaxyUsedCarLogo, IdCardIcon, Images } from "assets";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Link, json, useLocation, useNavigate } from "react-router-dom";
import ConfirmationDialog from "components/Dialog/ConfirmationDialog";
import MenuIcon from '@mui/icons-material/Menu';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import moment from "moment";
import AuthServices from "services/Auth";
import { ErrorToaster, SuccessToaster } from "components/Toaster";
import SystemServices from "services/System";
import SettingsIcon from "@mui/icons-material/Settings";
import { Logout } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';

function DropDown({ anchorEl, openDropdown, handleClose }) {
	const [confirmationDialog, setConfirmationDialog] = useState(false);
	const { user, userLogout } = useAuth();
	const navigate = useNavigate();
	const handleLogout = async (formData) => {
	  let obj = {
		fcm_token: localStorage.getItem("fcmToken"),
	  };
	  try {
		const { message } = await AuthServices.handleLogout(obj);
		navigate("/");
	  } catch (error) {
		ErrorToaster(error);
	  }
	};
  
	return (
		<>
	  <Menu
		anchorEl={anchorEl}
		open={openDropdown}
		onClose={handleClose}
		onClick={handleClose}
		transformOrigin={{ horizontal: "right", vertical: "top" }}
		anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
		PaperProps={{
		  elevation: 0,
		  sx: {
			width: 200,
			// height: 120, 
			overflow: "visible",
			filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
			mt: 1.5,
			borderRadius:"10px",  
			".MuiSvgIcon-root": {
			  width: 20,
			  height: 20,
			  ml: 0.5,
			  mr: 0.5,
			},
			"&:before": {
			  content: '""',
			  display: "block",
			  position: "absolute",
			  top: 0,
			  right: 14,
			  width: 10,
			  height: 10,
			  transform: "translateY(-50%) rotate(45deg)",
			  zIndex: 0,
			},
		  },
		}}
	  >
		<MenuItem onClick={() => navigate("/account-setting")}>
		  <ListItemIcon>
			<SettingsIcon />
		  </ListItemIcon>
		  Account Setting
		</MenuItem>
		<Divider sx={{ my: 0.5 }} />
		<MenuItem onClick={() => setConfirmationDialog(true)}>
		  <ListItemIcon>
			<Logout />
		  </ListItemIcon>
		  Logout
		</MenuItem>
	  </Menu>
	  <ConfirmationDialog
        open={confirmationDialog}
        onClose={() => setConfirmationDialog(false)}
        message={"Are you sure you want to logout?"}
        action={() => {
          setConfirmationDialog(false);
          userLogout();
          handleLogout();
        }}
      />
		</>
	);
  }

function Header({ onClick }) {
	const { user, userLogout } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorEls, setAnchorEls] = useState(null);
	const openDropdown = Boolean(anchorEl);

	const [notifications, setNotifications] = useState([])
	const [notificationCount, setNotificationCount] = useState(0)

const [currentPage, setCurrentPage] = useState(``)


  

	const getNotificationCount = async () => {
		try {
			const { data } = await SystemServices.getNotificationsCount()
			console.log(data, 'onlynotfi');

			setNotificationCount(data?.notifications)

		} catch (error) {
			ErrorToaster(error)
		}
	}
	const open = Boolean(anchorEls);
	const handleClick = (event) => {


		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {

		setAnchorEl(false);
	};

	// *For Dialog Box
	const [confirmationDialog, setConfirmationDialog] = useState(false);
	const handleLogout = async (formData) => {
		let obj = {
			fcm_token: localStorage.getItem('fcmToken')
		}
		try {
			const { message } = await AuthServices.handleLogout(obj);




		}

		catch (error) {
			ErrorToaster(error);
		}
	}
	const notificationSeen = async (e, id, route) => {
		e.preventDefault()
		let obj = {
			notification_id: id
		}
		try {
			const { message } = await SystemServices.notificationSeen(obj);
			handleClose()
			console.log(route);
			navigate(route ? route : '/dashboard')



		}

		catch (error) {
			ErrorToaster(error);
		}
	}

	useEffect(() => {

		
		let pageName=localStorage.getItem('currentRoute')
		setCurrentPage(pageName)
		localStorage.setItem('currentUrl',location?.pathname)
	
		getNotificationCount()
	}, [location?.pathname])



	return (
		<>
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				bgcolor: '#FAFAFA',
				px: 3,
				py: 1,
				boxShadow: "0px 4px 8px 0px #9B9B9B1A",
			}}
		>
			{/* ========== Confirmation Dialog ========== */}
			<ConfirmationDialog
				open={confirmationDialog}
				onClose={() => setConfirmationDialog(false)}
				message={"Are you sure you want to logout?"}
				action={() => {
					setConfirmationDialog(false);
					userLogout();
					handleLogout();
				}}
			/>
			{console.log(location?.pathname)}
			<Box display={'flex'} alignItems={'center'} gap={2} >
				{ location?.pathname != '/dashboard' ? <> <Typography sx={{color:'#434343'}}>Dashboard</Typography>
				<Box sx={{border:'0.5px solid #434343',height:"30px"}}></Box>
				<Typography sx={{color:Colors.textColorBlue,fontSize:'22px',fontWeight:700}}>{currentPage}</Typography></>  :<Typography sx={{color:Colors.textColorBlue,fontSize:'22px',fontWeight:700}}>Dashboard</Typography>}
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
			

				
			{console.log(notificationCount,'notificationCount')}
			
				<Badge variant={notificationCount > 0  ? 'dot' : ''}   color="error" onClick={()=>{navigate("/notifcations");localStorage.setItem("currentRoute", 'Notifications')}}>
					<NotificationsIcon sx={{ cursor: 'pointer',color:"#107ec3" }} id="basic-button"
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
					 color="action" />
				</Badge>
				<Menu
					id="basic-menu"
					anchorEl={anchorEls}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
					PaperProps={{
						style: {
							width: '400px',
							height: '500px',
							left: '100px !important', // Set the desired width here,
							borderRadius: '25px !important'
						},
					}}

				>
					<Typography variant="h5" sx={{ fontWeight: 700, ':hover': { color: Colors.primary }, m: 1, ml: 2 }}>
						Notifications
					</Typography>
					{notifications?.map((item, index) => {
						let link = JSON.parse(item?.data)



						return (
							<React.Fragment key={index}>
								<Link to={link?.route ? link?.route : '/dashboard'}>	<MenuItem onClick={handleClose}>
									<Box sx={{ display: 'flex', gap: '5px' }}>
										<Box sx={{ width: '45px', height: '45px', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#1890ff1a', gap: '5px' }}>
											<InfoOutlinedIcon sx={{ fontSize: '30px', color: Colors.primary }} />
										</Box>

										<Box sx={{ whiteSpace: "break-spaces" }}>
											{true && (
												<Typography variant="body2">
													<b>{item.content}</b>
												</Typography>
											)}
											{true && (
												<Typography
													variant="caption"
													sx={{ color: Colors.grey }}
												>
													{moment(item?.created_at).format("MM-DD-YYYY, hh:mm A")}
												</Typography>
											)}
										</Box>
									</Box>
								</MenuItem>
								</Link>
								<Divider sx={{ backgroundColor: '#cccccc' }} />
							</React.Fragment>
						);
					})}

					{/* <MenuItem onClick={handleClose}>
						<Box sx={{ display: 'flex', gap: '5px' }}>
							<Box sx={{ width: '45px', height: "45px", borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#1890ff1a', gap: '5px' }}>
								<InfoOutlinedIcon sx={{ fontSize: '30px', color: Colors.primary }} />
							</Box>

							<Box sx={{ whiteSpace: "break-spaces" }}>

								{true && (
									<Typography variant="body2">
										<b>{("message")}</b> sadasd
									</Typography>
								)}
								{true && (
									<Typography
										variant="caption"
										sx={{ color: Colors.grey }}
									>
										{moment().format("MM-DD-YYYY, hh:mm A")}
									</Typography>
								)}

							</Box>

						</Box>
					</MenuItem> */}

				</Menu>
				<IconButton  onClick={(e) => setAnchorEl(e.currentTarget)} sx={{background:"none",":hover":{
					background:"none"
				}}}>
				&nbsp;&nbsp;
				<Avatar className="avatar-image" alt={user?.name}  sx={{ width: 35, height: 35 }} src={process.env.REACT_APP_IMAGE_BASE_URL + user?.picture} />
				&nbsp;&nbsp;<span style={{fontSize:'15px',fontWeight:'bold'}}>{user?.name}</span>
				</IconButton>
			</Box>
		</Box>

		      <DropDown
                anchorEl={anchorEl}
                openDropdown={openDropdown}
                handleClose={() => setAnchorEl(null)}
              />
	
		</>
	);
}

export default Header;
